<template>
    <div class="evaluation">
        <headBack>
            <template v-slot:title>
                <div class='title'>
                    评价服务
                </div>
            </template>
        </headBack>
        <div class='header_cont'></div>
        <div class='evaluation_content'>
            <div class='nurse'>
                <img src='https://static.yihu365.cn/img/h5Img/assets/img/order/nurse.png'/>
                <div>王娟</div>
                <div class='nurse_num'>（第3次服务）</div>
            </div>
            <div class='star'>
                <van-rate
                    v-model="value"
                    :size="25"
                    color="#FFB844"
                    void-icon="star"
                    void-color="#eee"
                    />
                <div>非常满意，无可挑剔</div>
            </div>
            <div class='status_cont'>
                <div class='active'>
                    上门准时
                </div>
                <div>
                    全程戴口罩手套
                </div>
                <div>
                    全程戴口罩手套
                </div>
                <div>
                    全程戴口罩手套
                </div>
            </div>
            <div class='mes_cont'>
                <van-field
                    v-model="message"
                    rows="2"
                    autosize
                    type="textarea"
                    maxlength="50"
                    placeholder="其他想说的"
                    show-word-limit
                    class='field'
                    />
            </div>

            <van-button type="primary" block round color='#00C291' style='margin-top:20px'>发表评论</van-button>

            <div class='radio_cont'>
                <div class='radio_list'>
                    <div class='flex_start'>
                        <van-checkbox v-model="checked" checked-color='#00C291' icon-size='18px'></van-checkbox>
                        <div class='radio_name'>默认评论模板</div>
                    </div>
                    <div class='radio_tips'>
                        细心耐心很温柔的护士，服务很周到，家人很喜欢，给 个大赞！
                    </div>
                </div>
                 <div class='radio_list'>
                    <div class='flex_start'>
                        <van-checkbox v-model="checked" checked-color='#00C291' icon-size='18px'></van-checkbox>
                        <div class='radio_name'>匿名评论</div>
                    </div>
                </div>
            </div>


        </div>
    </div>
</template>
<script>
import { onMounted, ref, computed } from "vue"
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { Dialog } from 'vant'



export default {
  name: 'evaluation',
  components: {

  },
  setup () {
    let router = useRouter()
    const store = useStore()
    const route = useRoute()
    let payShow = ref(false);
    let concelShow = ref(false);
    let checked = ref(1);
    const active = ref(0);
    let value = ref(1)
    return {
        value,
        router,
        checked,
        active,
        payShow,
        concelShow
    }
  },

}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
    .radio_cont{
        padding:30px;
        .radio_list{
            font-size: 28px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #313131;
            margin-bottom: 30px;
            .radio_name{
                margin-left:25px;
            }
        }
        .radio_tips{
            font-size: 26px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            margin-top:20px;
        }
    }
    .mes_cont{
        padding:30px;
        padding-top:0px;
        .field{
            border:1px solid #919090;
        }
    }
    .status_cont{
        font-size: 24px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #919090;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        padding:30px;
        div{
            width: 275px;
            border:1px solid #919090;
            height: 52px;
            line-height: 52px;
            text-align: center;
            margin-bottom: 22px;
            border-radius: 5px;
        }
        .active{
            color:#00C291;
            border-color:#00C291;
            background: #F4FFFA;
        }
    }
    .evaluation_content{
        padding:65px 50px;
    }
    .nurse{
        display: flex;
        align-items: center;
        font-size: 30px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #313131;
        .nurse_num{
            font-size: 26px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            margin-left:10px;
        }
        img{
            width: 90px;
            margin-right:22px;

        }
    }
    .star{
        font-size: 26px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFB844;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top:30px;
        &>div{
            margin-top:20px;
        }
    }


</style>
